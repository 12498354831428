.modal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.75);
	z-index: 999;
}

.hidden {
	display: none;
}

.content {
	background: white;
	margin: 5% auto;
	width: 30vw;

	padding: 32px 48px;
	text-align: center;
	position: relative;
}

.title {
	font-size: 32px;
	font-weight: 600;
}

.text {
	font-size: 18px;
	margin: 12px;
	line-height: 24px;
}

.input {
	padding: 12px 24px;
	font-size: 18px;
	border: 1px solid black;

	margin: 24px 0;
}

.checkbox {
	width: 32px;
}

.button {
	margin-top: 12px;
	background: black;
	color: white;
	font-size: 20px;
	padding: 12px 48px;
	cursor: pointer;
	border: none;
}

.button:hover {
	background: rgba(0, 0, 0, 0.9);
	transition: background-color 0.2s;
}

.closeButton {
	width: 24px;
	height: 24px;

	position: absolute;
	top: 12px;
	right: 12px;

	cursor: pointer;
}

@media screen and (min-width: 1401px) {
	.content {
		width: 22vw;
	}
}

@media screen and (max-width: 992px) {
	.content {
		width: 70%;
	}
}
