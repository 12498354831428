.footer {
	background-color: #000;
	color: white;
}

.title {
	margin: 12px 0;
}

.footer p {
	font-size: 14px;
}

.policy {
	margin-top: 12px;
	color: white;
	text-decoration: underline;
}
