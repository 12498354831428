.banner {
	display: flex;
	overflow: hidden;
	position: relative;
}

.image {
	width: 50%;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: right bottom;
}

.content {
	position: relative;
	width: 50%;
	min-height: 100%;

	background-image: url("https://sales.maximum-chery.ru/img/pics/backgrounds/bg-text.jpg");
	background-repeat: no-repeat;
	background-size: auto 100%;

	padding: 24px 24px;
}

.content .subtitle {
	font-size: 28px;
	font-weight: 500;
}

.content li {
	margin-top: 12px;
	margin-left: -24px;
}

.title {
	font-weight: 600;
	font-size: 28px;
}

strong {
	color: #00afdc;
	font-size: 38px;
}

.button {
	background-color: #a3171f;
	border: none;
	color: white;
	padding: 12px 24px;
	font-size: 20px;
	margin-top: 12px;
	cursor: pointer;
	transition: background-color 0.2s;
	user-select: none;
}

.button:hover {
	background-color: #5e0808;
}

.footer {
	font-size: 18px;
    font-weight: 400;
    margin-top: 12px;
    width: 90%;
}

@media screen and (max-width: 992px) {
	.image {
		display: none;
	}

	.content {
		width: 100%;
		background-size: auto 100%;
	}
}