.contacts {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.map {
	width: 100%;
	height: 400px;
}
.map iframe {
	width: 100%;
	height: 100%;
}

.info {
	background: white;
	position: absolute;
	width: auto;
	left: 10%;
	top: 25%;

	padding: 24px 24px;

	border-radius: 4px;

	box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.title {
	font-weight: 600;
	font-size: 24px;
}

.description {
	margin: 12px 0 8px;
	font-size: 16px;
}

.phone {
	font-weight: 600;
}

.time {
	margin-top: 4px;
}

@media screen and (max-width: 992px) {
	.info {
		background: white;
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		top: auto;

		padding: 24px 24px;

		border-radius: 4px;

		box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
	}
}
